import { useState, useEffect } from "react";
import {
  Box,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Chip,
  TextField,
  MenuItem,
  TablePagination,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Snackbar,
  Alert,
  FormControl,
  Select,
  InputLabel,
  Grid,
} from "@mui/material";
import {
  Visibility,
  AssignmentInd,
  Block,
  Search as SearchIcon,
} from "@mui/icons-material";
import api from "../../utils/api";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import { formatDateTime } from "../../utils/dateUtils";

const statusOptions = [
  { value: "R", label: "New Leads" },
  { value: "A", label: "Assigned" },
  { value: "T", label: "Active" },
  { value: "J", label: "Rejected" },
];

const statusMap = {
  R: "new",
  A: "assigned",
  T: "active",
  J: "rejected",
};

const LeadManagement = () => {
  useDocumentTitle("Lead Management");

  const [leads, setLeads] = useState([]);
  const [agents, setAgents] = useState([]); // For agent assignment dropdown
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [statusFilter, setStatusFilter] = useState("R"); // Default to show new leads
  const [searchQuery, setSearchQuery] = useState("");
  const [viewDialog, setViewDialog] = useState(false);
  const [actionDialog, setActionDialog] = useState({
    open: false,
    type: null, // 'assign' or 'reject'
    leadId: null,
  });
  const [selectedAgent, setSelectedAgent] = useState("");
  const [rejectReason, setRejectReason] = useState("");
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [detailedLead, setDetailedLead] = useState(null);
  const [loadingDetails, setLoadingDetails] = useState(false);

  const fetchLeads = async () => {
    try {
      setLoading(true);
      const response = await api.get("agent/lead/get");
      setLeads(response.data.data || []);
    } catch (error) {
      setSnackbar({
        open: true,
        message: error.response?.data?.message || "Failed to fetch leads",
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchAgents = async () => {
    try {
      const response = await api.post("agent/get", {});
      const activeAgents = (response.data.data || []).filter(
        (agent) => agent.status === "A"
      );
      setAgents(activeAgents);
    } catch (error) {
      console.error("Failed to fetch agents:", error);
    }
  };

  const fetchLeadDetails = async (customerId) => {
    try {
      setLoadingDetails(true);
      const response = await api.get(`agent/lead/customer/${customerId}`);
      setDetailedLead(response.data.data);
    } catch (error) {
      setSnackbar({
        open: true,
        message:
          error.response?.data?.message || "Failed to fetch lead details",
        severity: "error",
      });
    } finally {
      setLoadingDetails(false);
    }
  };

  useEffect(() => {
    fetchLeads();
    fetchAgents();
  }, []);

  const handleLeadAction = async () => {
    try {
      if (actionDialog.type === "assign") {
        await api.post("agent/assign", {
          assign_agent_id: selectedAgent,
          customer_id: actionDialog.leadId,
        });

        setSnackbar({
          open: true,
          message: "Lead assigned successfully",
          severity: "success",
        });
        fetchLeads(); // Refresh leads after successful assignment
      } else if (actionDialog.type === "reject") {
        await api.post(`agent/lead/reject/${actionDialog.leadId}`, {
          reason: rejectReason,
        });
        setSnackbar({
          open: true,
          message: "Lead rejected successfully",
          severity: "success",
        });
        fetchLeads();
      }
    } catch (error) {
      setSnackbar({
        open: true,
        message: error.response?.data?.message || "Action failed",
        severity: "error",
      });
    }
    handleActionDialogClose();
  };

  const handleActionDialogClose = () => {
    setActionDialog({ open: false, type: null, leadId: null });
    setSelectedAgent("");
    setRejectReason("");
  };

  const getStatusChipProps = (status) => {
    const statusColorMap = {
      R: { color: "warning", label: "New Lead" },
      A: { color: "info", label: "Assigned" },
      T: { color: "success", label: "Active" },
      J: { color: "error", label: "Rejected" },
    };
    return statusColorMap[status] || { color: "default", label: status };
  };

  const getLeadTypeChipProps = (leadType) => {
    return {
      color: leadType === 'online' ? 'primary' : 'success',
      label: leadType === 'online' ? 'Online' : 'Excel',
    };
  };

  const filteredLeads = leads.filter((lead) => {
    const matchesStatus = lead.status === statusFilter;
    const matchesSearch =
      lead.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      lead.email.toLowerCase().includes(searchQuery.toLowerCase());
    return matchesStatus && matchesSearch;
  });

  const handleStatusChange = (event) => {
    setStatusFilter(event.target.value);
    setPage(0); // Reset to first page when filter changes
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setPage(0); // Reset to first page when search changes
  };

  useEffect(() => {
    const maxPage = Math.ceil(filteredLeads.length / rowsPerPage) - 1;
    if (maxPage < 0) {
      setPage(0);
    } else if (page > maxPage) {
      setPage(maxPage);
    }
  }, [filteredLeads.length, page, rowsPerPage]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page when rows per page changes
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h5" gutterBottom>
        Lead Management
      </Typography>

      <Paper sx={{ p: 2, mb: 2 }}>
        <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
          <TextField
            select
            label="Status"
            value={statusFilter}
            onChange={handleStatusChange}
            sx={{ minWidth: 150 }}
          >
            {statusOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            label="Search"
            value={searchQuery}
            onChange={handleSearchChange}
            sx={{ minWidth: 200 }}
            InputProps={{
              endAdornment: <SearchIcon color="action" />,
            }}
          />
        </Box>

        {loading ? (
          <Box sx={{ display: "flex", justifyContent: "center", my: 3 }}>
            <CircularProgress />
          </Box>
        ) : (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Customer ID</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Phone</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Lead Type</TableCell>
                  <TableCell>Submitted Date</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredLeads
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((lead) => (
                    <TableRow key={lead.customer_id}>
                      <TableCell>{lead.customer_id}</TableCell>
                      <TableCell>{lead.name}</TableCell>
                      <TableCell>{lead.email}</TableCell>
                      <TableCell>{lead.phone_number}</TableCell>
                      <TableCell>
                        <Chip
                          {...getStatusChipProps(lead.status)}
                          size="small"
                        />
                      </TableCell>
                      <TableCell>
                        <Chip {...getLeadTypeChipProps(lead.lead_type)} size="small" />
                      </TableCell>
                      <TableCell>
                        {formatDateTime(lead.form_submitted_at)}
                      </TableCell>
                      <TableCell>
                        <IconButton
                          onClick={() => {
                            setViewDialog(true);
                            fetchLeadDetails(lead.customer_id);
                          }}
                          color="primary"
                          title="View Details"
                        >
                          <Visibility />
                        </IconButton>
                        {(lead.status === "R" ||
                          statusMap[lead.status] === "new") && (
                          <>
                            <IconButton
                              onClick={() =>
                                setActionDialog({
                                  open: true,
                                  type: "assign",
                                  leadId: lead.customer_id,
                                })
                              }
                              color="success"
                              title="Assign Lead"
                            >
                              <AssignmentInd />
                            </IconButton>
                            <IconButton
                              onClick={() =>
                                setActionDialog({
                                  open: true,
                                  type: "reject",
                                  leadId: lead.customer_id,
                                })
                              }
                              color="error"
                              title="Reject Lead"
                            >
                              <Block />
                            </IconButton>
                          </>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        <TablePagination
          component="div"
          count={filteredLeads.length}
          page={page}
          onPageChange={(e, newPage) => setPage(newPage)}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      {/* View Lead Details Dialog */}
      <Dialog
        open={viewDialog}
        onClose={() => {
          setViewDialog(false);
          setDetailedLead(null);
        }}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Lead Details</DialogTitle>
        <DialogContent>
          {loadingDetails ? (
            <Box sx={{ display: "flex", justifyContent: "center", p: 3 }}>
              <CircularProgress />
            </Box>
          ) : detailedLead ? (
            <Box sx={{ p: 2 }}>
              <Typography variant="h6" gutterBottom>
                Basic Information
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography>
                    <strong>Customer ID:</strong> {detailedLead.customer_id}
                  </Typography>
                  <Typography>
                    <strong>Name:</strong> {detailedLead.name}
                  </Typography>
                  <Typography>
                    <strong>Email:</strong> {detailedLead.email}
                  </Typography>
                  <Typography>
                    <strong>Phone:</strong> {detailedLead.phone_number}
                  </Typography>
                  <Typography>
                    <strong>Lead Type:</strong>{" "}
                    <Chip 
                      {...getLeadTypeChipProps(detailedLead.lead_type)} 
                      size="small" 
                      sx={{ ml: 1 }} 
                    />
                  </Typography>
                  <Typography>
                    <strong>Business Name:</strong>{" "}
                    {detailedLead.business_name || "N/A"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography>
                    <strong>PAN Number:</strong>{" "}
                    {detailedLead.pan_number || "Not provided"}
                  </Typography>
                  <Typography>
                    <strong>Occupation:</strong> {detailedLead.occupation}
                  </Typography>
                  <Typography>
                    <strong>Monthly Income:</strong> ₹
                    {Math.round(detailedLead.monthly_income)}
                  </Typography>
                  <Typography>
                    <strong>ITR Filing Status:</strong>{" "}
                    {detailedLead.itr_filing_status === "Y" ? "Yes" : "No"}
                  </Typography>
                </Grid>
              </Grid>

              {detailedLead.assigned_agent_id && (
                <>
                  <Typography variant="h6" sx={{ mt: 3, mb: 1 }}>
                    Assignment Details
                  </Typography>
                  <Typography>
                    <strong>Assigned Agent:</strong>{" "}
                    {detailedLead.assigned_agent_name}
                  </Typography>
                  <Typography>
                    <strong>Agent ID:</strong> {detailedLead.assigned_agent_id}
                  </Typography>
                </>
              )}

              {detailedLead.application_id && (
                <>
                  <Typography variant="h6" sx={{ mt: 3, mb: 1 }}>
                    Application Details
                  </Typography>
                  <Typography>
                    <strong>Application ID:</strong>{" "}
                    {detailedLead.application_id}
                  </Typography>
                  <Typography>
                    <strong>Application Status:</strong>{" "}
                    {detailedLead.application_status}
                  </Typography>
                  <Typography>
                    <strong>Created At:</strong>{" "}
                    {formatDateTime(detailedLead.application_created_at)}
                  </Typography>
                </>
              )}

              <Typography variant="h6" sx={{ mt: 3, mb: 1 }}>
                Status Information
              </Typography>
              <Typography>
                <strong>Status:</strong>{" "}
                <Chip
                  {...getStatusChipProps(detailedLead.customer_status)}
                  size="small"
                />
              </Typography>
              <Typography>
                <strong>Form Submitted:</strong>{" "}
                {formatDateTime(detailedLead.form_submitted_at)}
              </Typography>

              {detailedLead.rejected_at && (
                <>
                  <Typography variant="h6" sx={{ mt: 3, mb: 1 }}>
                    Rejection Details
                  </Typography>
                  <Typography>
                    <strong>Rejected At:</strong>{" "}
                    {formatDateTime(detailedLead.rejected_at)}
                  </Typography>
                  <Typography>
                    <strong>Rejected By:</strong> {detailedLead.rejected_by}
                  </Typography>
                  <Typography>
                    <strong>Rejection Reason:</strong>{" "}
                    {detailedLead.rejection_reason}
                  </Typography>
                </>
              )}
            </Box>
          ) : (
            <Typography color="error">Failed to load lead details</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setViewDialog(false);
              setDetailedLead(null);
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Action Dialog (Assign/Reject) */}
      <Dialog
        open={actionDialog.open}
        onClose={handleActionDialogClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          {actionDialog.type === "assign" ? "Assign Lead" : "Reject Lead"}
        </DialogTitle>
        <DialogContent>
          {actionDialog.type === "assign" ? (
            <FormControl fullWidth sx={{ mt: 2 }}>
              <InputLabel>Select Agent</InputLabel>
              <Select
                value={selectedAgent}
                onChange={(e) => setSelectedAgent(e.target.value)}
                label="Select Agent"
              >
                {agents.map((agent) => (
                  <MenuItem key={agent.agent_id} value={agent.agent_id}>
                    {`${agent.name} (ID: ${agent.agent_id})`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : (
            <TextField
              fullWidth
              multiline
              rows={4}
              label="Rejection Reason"
              value={rejectReason}
              onChange={(e) => setRejectReason(e.target.value)}
              sx={{ mt: 2 }}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleActionDialogClose}>Cancel</Button>
          <Button
            onClick={handleLeadAction}
            color="primary"
            disabled={
              (actionDialog.type === "assign" && !selectedAgent) ||
              (actionDialog.type === "reject" && !rejectReason)
            }
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default LeadManagement;
