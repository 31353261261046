import {
  Typography,
  Grid,
  Card,
  CardContent,
  Box,
  CardHeader,
  IconButton,
  LinearProgress,
} from "@mui/material";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import { useState, useEffect } from "react";
import api from "../../utils/api";
// Import icons
import {
  PendingActions,
  Group,
  Assessment,
  Refresh,
  TrendingUp,
  AccessTime,
  CheckCircle,
  Timeline,
} from "@mui/icons-material";

const AdminDashboard = () => {
  useDocumentTitle("Dashboard");
  const [loading, setLoading] = useState(true);
  const [dashboardData, setDashboardData] = useState({
    leads: {
      pending: 0,
      total: 0,
      assigned: 0,
      active: 0,
      rejected: 0,
      todayNew: 0,
    },
    agents: {
      active: 0,
      pending: 0,
      inactive: 0,
      totalEnrolled: 0,
    },
    performance: {
      avgProcessingTime: 0,
      completionRate: 0,
    },
  });

  const fetchDashboardStats = async () => {
    try {
      setLoading(true);
      const response = await api.get("agent/dashboard/stats");
      setDashboardData(response.data.data);
    } catch (error) {
      console.error("Failed to fetch dashboard stats:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDashboardStats();
  }, []);

  const mainStats = [
    {
      title: "Pending Leads",
      value: dashboardData.leads.pending,
      icon: <PendingActions color="warning" />,
      description: "Leads waiting for assignment",
    },
    {
      title: "Active Leads",
      value: dashboardData.leads.active,
      icon: <Assessment color="success" />,
      description: "Currently active leads",
    },
    {
      title: "Active Agents",
      value: dashboardData.agents.active,
      icon: <Group color="info" />,
      description: "Currently active agents",
    },
  ];

  const leadMetrics = [
    {
      title: "Today's New Leads",
      value: dashboardData.leads.todayNew,
      icon: <TrendingUp color="info" />,
    },
    {
      title: "Completion Rate",
      value: `${dashboardData.performance.completionRate}%`,
      icon: <Timeline color="info" />,
    },
  ];

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 3 }}>
        <Typography variant="h4">Dashboard Overview</Typography>
        <IconButton onClick={fetchDashboardStats} disabled={loading}>
          <Refresh />
        </IconButton>
      </Box>

      {loading && <LinearProgress sx={{ mb: 2 }} />}

      <Grid container spacing={3}>
        {/* Main Stats */}
        {mainStats.map((stat, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card>
              <CardHeader title={stat.title} />
              <CardContent>
                <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                  {stat.icon}
                  <Typography variant="h3" sx={{ ml: 2 }}>
                    {stat.value}
                  </Typography>
                </Box>
                <Typography variant="body2" color="text.secondary">
                  {stat.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}

        {/* Lead Distribution */}
        <Grid item xs={12} md={6}>
          <Card>
            <CardHeader title="Lead Distribution" />
            <CardContent>
              <Box sx={{ mb: 2 }}>
                <Typography variant="body2" sx={{ mb: 1 }}>
                  Active ({dashboardData.leads.active})
                </Typography>
                <LinearProgress
                  variant="determinate"
                  value={
                    (dashboardData.leads.active / dashboardData.leads.total) *
                    100
                  }
                  color="success"
                  sx={{ mb: 1 }}
                />
                <Typography variant="body2" sx={{ mb: 1 }}>
                  Assigned ({dashboardData.leads.assigned})
                </Typography>
                <LinearProgress
                  variant="determinate"
                  value={
                    (dashboardData.leads.assigned / dashboardData.leads.total) *
                    100
                  }
                  color="info"
                  sx={{ mb: 1 }}
                />
                <Typography variant="body2" sx={{ mb: 1 }}>
                  Pending ({dashboardData.leads.pending})
                </Typography>
                <LinearProgress
                  variant="determinate"
                  value={
                    (dashboardData.leads.pending / dashboardData.leads.total) *
                    100
                  }
                  color="warning"
                  sx={{ mb: 1 }}
                />
                <Typography variant="body2" sx={{ mb: 1 }}>
                  Rejected ({dashboardData.leads.rejected})
                </Typography>
                <LinearProgress
                  variant="determinate"
                  value={
                    (dashboardData.leads.rejected / dashboardData.leads.total) *
                    100
                  }
                  color="error"
                />
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {/* Agent Status */}
        <Grid item xs={12} md={6}>
          <Card>
            <CardHeader title="Agent Status" />
            <CardContent>
              <Box sx={{ mb: 2 }}>
                <Typography variant="body2" sx={{ mb: 1 }}>
                  Active ({dashboardData.agents.active})
                </Typography>
                <LinearProgress
                  variant="determinate"
                  value={
                    (dashboardData.agents.active /
                      dashboardData.agents.totalEnrolled) *
                    100
                  }
                  color="success"
                  sx={{ mb: 1 }}
                />
                <Typography variant="body2" sx={{ mb: 1 }}>
                  Pending Activation ({dashboardData.agents.pending})
                </Typography>
                <LinearProgress
                  variant="determinate"
                  value={
                    (dashboardData.agents.pending /
                      dashboardData.agents.totalEnrolled) *
                    100
                  }
                  color="warning"
                  sx={{ mb: 1 }}
                />
                <Typography variant="body2" sx={{ mb: 1 }}>
                  Inactive ({dashboardData.agents.inactive})
                </Typography>
                <LinearProgress
                  variant="determinate"
                  value={
                    (dashboardData.agents.inactive /
                      dashboardData.agents.totalEnrolled) *
                    100
                  }
                  color="error"
                />
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {/* Additional Metrics */}
        {leadMetrics.map((metric, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card>
              <CardContent>
                <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                  {metric.icon}
                  <Typography variant="h6" sx={{ ml: 1 }}>
                    {metric.title}
                  </Typography>
                </Box>
                <Typography variant="h4">{metric.value}</Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default AdminDashboard;
