import {
  Box,
  Typography,
  Grid,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
  Card,
  CardContent,
  CardHeader,
} from "@mui/material";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import { useState, useEffect } from "react";
import api from "../../utils/api";
import {
  Assignment,
  Pending,
  CheckCircle,
  Refresh,
  Timeline,
  Speed,
  TrendingUp,
} from "@mui/icons-material";
import { formatDateTime } from "../../utils/dateUtils";

const AgentDashboard = () => {
  useDocumentTitle("Dashboard");
  const [loading, setLoading] = useState(true);
  const [dashboardData, setDashboardData] = useState({
    leads: {
      assigned: 0,
      pending: 0,
      completed: 0,
      todayAssigned: 0,
    },
    performance: {
      avgResponseTime: 0,
      completionRate: 0,
      todayCompleted: 0,
    },
    recentActivity: [],
  });

  const fetchDashboardData = async () => {
    try {
      setLoading(true);
      const response = await api.get("/agent/dashboard/stats/agent");
      setDashboardData(response.data.data);
    } catch (error) {
      console.error("Failed to fetch dashboard data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDashboardData();
    // Refresh data every 5 minutes
    const interval = setInterval(fetchDashboardData, 300000);
    return () => clearInterval(interval);
  }, []);

  const mainStats = [
    {
      title: "Assigned Leads",
      value: dashboardData.leads.assigned,
      icon: <Assignment color="primary" />,
      description: "Total leads assigned to you",
    },
    {
      title: "Pending Actions",
      value: dashboardData.leads.pending,
      icon: <Pending color="warning" />,
      description: "Leads requiring your attention",
    },
    {
      title: "Completed",
      value: dashboardData.leads.completed,
      icon: <CheckCircle color="success" />,
      description: "Successfully processed leads",
    },
  ];

  const performanceMetrics = [
    {
      title: "Today's Assignments",
      value: dashboardData.leads.todayAssigned,
      icon: <TrendingUp color="info" />,
    },
    {
      title: "Today's Completed",
      value: dashboardData.performance.todayCompleted,
      icon: <Timeline color="info" />,
    },
  ];

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 3 }}>
        <Typography variant="h4">
          Welcome, {localStorage.getItem("agentName") || "Agent"}
        </Typography>
        <IconButton onClick={fetchDashboardData} disabled={loading}>
          <Refresh />
        </IconButton>
      </Box>

      {loading && <LinearProgress sx={{ mb: 2 }} />}

      {/* Main Stats */}
      <Grid container spacing={3}>
        {mainStats.map((stat, index) => (
          <Grid item xs={12} md={6} lg={4} key={index}>
            <Card>
              <CardContent>
                <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                  {stat.icon}
                  <Typography variant="h6" sx={{ ml: 1 }}>
                    {stat.title}
                  </Typography>
                </Box>
                <Typography variant="h3" component="div" sx={{ mb: 1 }}>
                  {stat.value}
                </Typography>
                <Typography color="text.secondary">
                  {stat.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}

        {/* Performance Overview */}
        <Grid item xs={12}>
          <Card>
            <CardHeader
              title="Performance Overview"
              action={
                <Typography variant="body2" color="text.secondary">
                  Completion Rate: {dashboardData.performance.completionRate}%
                </Typography>
              }
            />
            <CardContent>
              <LinearProgress
                variant="determinate"
                value={dashboardData.performance.completionRate}
                sx={{ height: 10, borderRadius: 5 }}
              />
            </CardContent>
          </Card>
        </Grid>

        {/* Additional Metrics */}
        {performanceMetrics.map((metric, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card>
              <CardContent>
                <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                  {metric.icon}
                  <Typography variant="body1" sx={{ ml: 1 }}>
                    {metric.title}
                  </Typography>
                </Box>
                <Typography variant="h4">{metric.value}</Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}

        {/* Recent Activity */}
        <Grid item xs={12}>
          <Card>
            <CardHeader title="Recent Activity" />
            <CardContent>
              {dashboardData.recentActivity.length > 0 ? (
                <List>
                  {dashboardData.recentActivity.map((activity, index) => (
                    <Box key={index}>
                      <ListItem>
                        <ListItemIcon>
                          {activity.status === "Processed" ? (
                            <CheckCircle color="success" />
                          ) : (
                            <Assignment color="warning" />
                          )}
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography variant="body1">
                              Application #{activity.application_id} -{" "}
                              {activity.customer_name}
                            </Typography>
                          }
                          secondary={
                            <>
                              <Typography
                                variant="body2"
                                color="text.secondary"
                              >
                                Status: {activity.status}
                              </Typography>
                              <Typography
                                variant="body2"
                                color="text.secondary"
                              >
                                {formatDateTime(activity.updated_at)}
                              </Typography>
                            </>
                          }
                        />
                      </ListItem>
                      {index < dashboardData.recentActivity.length - 1 && (
                        <Divider />
                      )}
                    </Box>
                  ))}
                </List>
              ) : (
                <Typography color="text.secondary">
                  No recent activity to display.
                </Typography>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AgentDashboard;
