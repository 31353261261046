import { useState, useEffect, useCallback } from "react";
import {
  Box,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Chip,
  TextField,
  MenuItem,
  TablePagination,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import {
  Block,
  LockReset,
  Search as SearchIcon,
  Edit as EditIcon,
} from "@mui/icons-material";
import api from "../../utils/api";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import { formatDateTime } from "../../utils/dateUtils";

const statusOptions = [
  { value: "A", label: "Active" },
  { value: "I", label: "Pending Activation" },
  { value: "D", label: "Deactivated" },
];

const AgentList = () => {
  useDocumentTitle("Agent Management");

  const [agents, setAgents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [statusFilter, setStatusFilter] = useState("I");
  const [searchQuery, setSearchQuery] = useState("");
  const [confirmDialog, setConfirmDialog] = useState({
    open: false,
    title: "",
    message: "",
    agentId: null,
    action: null,
  });
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [editDialog, setEditDialog] = useState({
    open: false,
    agentId: null,
    email: "",
    mobile: "",
  });
  const [editErrors, setEditErrors] = useState({});

  const fetchAgents = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.post("agent/get", {
        status: statusFilter,
      });
      const filteredResponse = response.data.data.filter(
        (agent) => agent.status === statusFilter
      );
      setAgents(filteredResponse || []);
    } catch (error) {
      console.error("Failed to fetch agents:", error);
      setSnackbar({
        open: true,
        message: error.response?.data?.message || "Failed to fetch agents",
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  }, [statusFilter]);

  useEffect(() => {
    fetchAgents();
  }, [fetchAgents]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleStatusChange = (event) => {
    setStatusFilter(event.target.value);
    setPage(0);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setPage(0);
  };

  const handleConfirmDialogClose = () => {
    setConfirmDialog({ ...confirmDialog, open: false });
  };

  const handleAction = async () => {
    try {
      if (confirmDialog.action === "status") {
        if (confirmDialog.newStatus === "I") {
          await api.delete(`agent/delete/${confirmDialog.agentId}`, {
            data: {},
          });
        } else {
          await api.post(`agent/update-status/${confirmDialog.agentId}`, {
            status: confirmDialog.newStatus,
          });
        }
        setSnackbar({
          open: true,
          message:
            confirmDialog.newStatus === "I"
              ? "Agent deactivated successfully"
              : "Agent activated successfully",
          severity: "success",
        });
        fetchAgents();
      } else if (confirmDialog.action === "resetPassword") {
        const response = await api.post(`agent/send-password-reset-link`, {
          email: confirmDialog.email,
        });
        setSnackbar({
          open: true,
          message:
            response.data.message || "Password reset link sent successfully",
          severity: "success",
        });
      }
    } catch (error) {
      setSnackbar({
        open: true,
        message: error.response?.data?.message || "Action failed",
        severity: "error",
      });
    }
    handleConfirmDialogClose();
  };

  const openConfirmDialog = (
    agentId,
    action,
    newStatus = null,
    email = null
  ) => {
    const messages = {
      status: `Are you sure you want to ${
        newStatus === "I" ? "deactivate" : "activate"
      } this agent?`,
      resetPassword:
        "Are you sure you want to send password reset link to this agent?",
    };

    setConfirmDialog({
      open: true,
      title:
        action === "status"
          ? newStatus === "I"
            ? "Confirm Deactivation"
            : "Confirm Activation"
          : "Confirm Reset Password",
      message: messages[action],
      agentId,
      action,
      newStatus,
      email,
    });
  };

  const handleEditDialogClose = () => {
    setEditDialog({
      open: false,
      agentId: null,
      email: "",
      mobile: "",
    });
    setEditErrors({});
  };

  const openEditDialog = (agent) => {
    setEditDialog({
      open: true,
      agentId: agent.agent_id,
      email: agent.email,
      mobile: agent.mobile,
    });
  };

  const handleEditSubmit = async () => {
    try {
      const response = await api.put(
        `agent/update-inactive/${editDialog.agentId}`,
        {
          email: editDialog.email,
          mobile: editDialog.mobile,
        }
      );

      setSnackbar({
        open: true,
        message: response.data.message,
        severity: "success",
      });

      handleEditDialogClose();
      fetchAgents();
    } catch (error) {
      const errors = error.response?.data?.errors;
      if (errors) {
        setEditErrors(errors);
      } else {
        setSnackbar({
          open: true,
          message: error.response?.data?.message || "Update failed",
          severity: "error",
        });
      }
    }
  };

  const filteredAgents = agents.filter((agent) => {
    const matchesSearch =
      agent.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      agent.email.toLowerCase().includes(searchQuery.toLowerCase());
    return matchesSearch;
  });

  const getStatusChipProps = (status) => {
    const statusColorMap = {
      A: { color: "success", label: "Active" },
      I: { color: "warning", label: "Pending Activation" },
      D: { color: "error", label: "Deactivated" },
    };
    return statusColorMap[status] || { color: "default", label: "Unknown" };
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const renderActionButtons = (agent) => (
    <>
      {agent.status === "A" && (
        <IconButton
          onClick={() => openConfirmDialog(agent.agent_id, "status", "I")}
          color="error"
          title="Deactivate Agent"
        >
          <Block />
        </IconButton>
      )}

      {agent.status === "I" && (
        <IconButton
          onClick={() => openEditDialog(agent)}
          color="primary"
          title="Edit Details"
        >
          <EditIcon />
        </IconButton>
      )}

      <IconButton
        onClick={() =>
          openConfirmDialog(agent.agent_id, "resetPassword", null, agent.email)
        }
        color="primary"
        title="Reset Password"
      >
        <LockReset />
      </IconButton>
    </>
  );

  const renderTableHeaders = () => (
    <TableHead>
      <TableRow>
        <TableCell>ID</TableCell>
        <TableCell>Name</TableCell>
        <TableCell>Email</TableCell>
        <TableCell>Mobile</TableCell>
        <TableCell>Status</TableCell>
        {statusFilter !== "I" && <TableCell>Last Login</TableCell>}
        <TableCell>Actions</TableCell>
      </TableRow>
    </TableHead>
  );

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h5" gutterBottom>
        Agent Management
      </Typography>

      <Paper sx={{ p: 2, mb: 2 }}>
        <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
          <TextField
            select
            label="Status"
            value={statusFilter}
            onChange={handleStatusChange}
            sx={{ minWidth: 150 }}
          >
            {statusOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            label="Search"
            value={searchQuery}
            onChange={handleSearchChange}
            sx={{ minWidth: 200 }}
            InputProps={{
              endAdornment: <SearchIcon color="action" />,
            }}
          />
        </Box>

        {loading ? (
          <Box sx={{ display: "flex", justifyContent: "center", my: 3 }}>
            <CircularProgress />
          </Box>
        ) : (
          <TableContainer>
            <Table>
              {renderTableHeaders()}
              <TableBody>
                {filteredAgents
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((agent) => (
                    <TableRow key={agent.agent_id}>
                      <TableCell>{agent.agent_id}</TableCell>
                      <TableCell>{agent.name}</TableCell>
                      <TableCell>{agent.email}</TableCell>
                      <TableCell>{agent.mobile}</TableCell>
                      <TableCell>
                        <Chip
                          {...getStatusChipProps(agent.status)}
                          size="small"
                        />
                      </TableCell>
                      {statusFilter !== "I" && (
                        <TableCell>
                          {agent.last_login ? formatDateTime(agent.last_login) : 'Never'}
                        </TableCell>
                      )}
                      <TableCell>{renderActionButtons(agent)}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        {!loading && (
          <TablePagination
            component="div"
            count={filteredAgents.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Paper>

      <Dialog open={confirmDialog.open} onClose={handleConfirmDialogClose}>
        <DialogTitle>{confirmDialog.title}</DialogTitle>
        <DialogContent>{confirmDialog.message}</DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmDialogClose}>Cancel</Button>
          <Button onClick={handleAction} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={editDialog.open}
        onClose={handleEditDialogClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Edit Agent Details</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="Email"
            value={editDialog.email}
            onChange={(e) =>
              setEditDialog({ ...editDialog, email: e.target.value })
            }
            margin="normal"
            error={Boolean(editErrors.email)}
            helperText={editErrors.email}
          />
          <TextField
            fullWidth
            label="Mobile"
            value={editDialog.mobile}
            onChange={(e) =>
              setEditDialog({ ...editDialog, mobile: e.target.value })
            }
            margin="normal"
            error={Boolean(editErrors.mobile)}
            helperText={editErrors.mobile}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditDialogClose}>Cancel</Button>
          <Button onClick={handleEditSubmit} color="primary">
            Update
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default AgentList;
