import { useState } from "react";
import {
  Box,
  Paper,
  Grid,
  TextField,
  Button,
  Typography,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Snackbar,
  Alert,
  CircularProgress,
} from "@mui/material";
import api from "../../utils/api";
import useDocumentTitle from "../../hooks/useDocumentTitle";

const OCCUPATION_OPTIONS = [
  { value: "service", label: "Service / Salaried" },
  { value: "business", label: "Business / Entrepreneurship" },
  { value: "freelancer", label: "Freelancer / Consultant" },
  { value: "student", label: "Student" },
  { value: "retired", label: "Retired" },
  { value: "unemployed", label: "Unemployed" },
  { value: "ngo", label: "Nonprofit / NGO" },
  { value: "homemaker", label: "Homemaker" },
  { value: "self-employed", label: "Self-Employed" },
  { value: "government", label: "Government / Public Sector" },
  { value: "agriculture", label: "Agriculture / Farming" },
  { value: "other", label: "Other" },
];

const CreateLead = () => {
  useDocumentTitle("Create Lead");
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    phone_number: "",
    email: "",
    pan_number: "",
    occupation: "",
    monthly_income: "",
    itr_filing_status: "",
  });

  const [errors, setErrors] = useState({});
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const validateField = (name, value) => {
    let error = "";

    switch (name) {
      case "name":
        if (!value) {
          error = "Name is required";
        } else if (!/^[a-zA-Z\s]*$/.test(value) || value.length > 100) {
          error = "Name should only contain letters and spaces (max 100 characters)";
        }
        break;

      case "phone_number":
        if (!value) {
          error = "Phone number is required";
        } else if (!/^[6-9]\d{9}$/.test(value)) {
          error = "Enter valid 10-digit mobile number";
        }
        break;

      case "email":
        if (!value) {
          error = "Email is required";
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
          error = "Enter valid email address";
        }
        break;

      case "pan_number":
        if (value && !/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(value)) {
          error = "Enter valid PAN number";
        }
        break;

      case "monthly_income":
        if (!value) {
          error = "Monthly income is required";
        } else if (isNaN(value) || Number(value) < 0) {
          error = "Monthly income must be a positive number";
        }
        break;

      case "occupation":
        if (!value) {
          error = "Occupation is required";
        }
        break;

      case "itr_filing_status":
        if (!value) {
          error = "ITR filing status is required";
        }
        break;

      default:
        break;
    }

    return error;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    
    const error = validateField(name, value);
    setErrors((prev) => ({
      ...prev,
      [name]: error,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate all fields
    const newErrors = {};
    Object.keys(formData).forEach((key) => {
      const error = validateField(key, formData[key]);
      if (error) newErrors[key] = error;
    });

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    setLoading(true);
    try {
      const response = await api.post("leads-contact-form", formData);
      setSnackbar({
        open: true,
        message: "Lead created successfully",
        severity: "success",
      });
      // Reset form after successful submission
      setFormData({
        name: "",
        phone_number: "",
        email: "",
        pan_number: "",
        occupation: "",
        monthly_income: "",
        itr_filing_status: "",
      });
      setErrors({});
    } catch (error) {
      setSnackbar({
        open: true,
        message: error.response?.data?.message || "Failed to create lead",
        severity: "error",
      });
      if (error.response?.data?.errors) {
        setErrors(error.response.data.errors);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Paper sx={{ p: 3 }}>
        <Typography variant="h5" gutterBottom>
          Create New Lead
        </Typography>

        <Typography 
          variant="body2" 
          color="text.secondary" 
          sx={{ 
            mb: 3, 
            backgroundColor: 'grey.50', 
            p: 2, 
            borderRadius: 1,
            border: '1px solid',
            borderColor: 'grey.200'
          }}
        >
          ⓘ Use this form when a customer calls you directly instead of filling out 
          the form on our website. This helps us create an online record of their request.
        </Typography>

        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                required
                label="Name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                error={!!errors.name}
                helperText={errors.name}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                required
                label="Phone Number"
                name="phone_number"
                value={formData.phone_number}
                onChange={handleChange}
                error={!!errors.phone_number}
                helperText={errors.phone_number}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                required
                label="Email"
                name="email"
                type="email"
                value={formData.email}
                onChange={handleChange}
                error={!!errors.email}
                helperText={errors.email}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="PAN Number"
                name="pan_number"
                value={formData.pan_number}
                onChange={handleChange}
                error={!!errors.pan_number}
                helperText={errors.pan_number}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl fullWidth required error={!!errors.occupation}>
                <InputLabel>Occupation</InputLabel>
                <Select
                  name="occupation"
                  value={formData.occupation}
                  onChange={handleChange}
                  label="Occupation"
                >
                  {OCCUPATION_OPTIONS.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
                {errors.occupation && (
                  <Typography color="error" variant="caption">
                    {errors.occupation}
                  </Typography>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                required
                label="Monthly Income"
                name="monthly_income"
                type="number"
                value={formData.monthly_income}
                onChange={handleChange}
                error={!!errors.monthly_income}
                helperText={errors.monthly_income}
                inputProps={{ min: 0 }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl fullWidth required error={!!errors.itr_filing_status}>
                <InputLabel>ITR Filing Status</InputLabel>
                <Select
                  name="itr_filing_status"
                  value={formData.itr_filing_status}
                  onChange={handleChange}
                  label="ITR Filing Status"
                >
                  <MenuItem value="yes">Yes</MenuItem>
                  <MenuItem value="no">No</MenuItem>
                </Select>
                {errors.itr_filing_status && (
                  <Typography color="error" variant="caption">
                    {errors.itr_filing_status}
                  </Typography>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
                <Button
                  type="submit"
                  variant="contained"
                  disabled={loading}
                  startIcon={loading && <CircularProgress size={20} />}
                >
                  {loading ? "Creating..." : "Create Lead"}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Paper>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar((prev) => ({ ...prev, open: false }))}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => setSnackbar((prev) => ({ ...prev, open: false }))}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default CreateLead; 