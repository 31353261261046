import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import ProtectedRoute from "./components/ProtectedRoute";
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import AdminLayout from "./components/layouts/AdminLayout";
import AgentLayout from "./components/layouts/AgentLayout";
import AdminDashboard from "./pages/admin/AdminDashboard";
import AgentDashboard from "./pages/agent/AgentDashboard";
import AgentEnrollment from "./pages/admin/AgentEnrollment";
import AgentList from "./pages/admin/AgentList";
import LeadManagement from "./pages/admin/LeadManagement";
import AssignedLeads from "./pages/agent/AssignedLeads";
import EditLead from "./pages/agent/EditLead";
import ProfileManagement from "./pages/common/ProfileManagement";
import Settings from "./pages/common/Settings";
import Reports from "./pages/admin/Reports";
import { ThemeProvider as MUIThemeProvider, createTheme } from "@mui/material";
import {
  ThemeProvider as CustomThemeProvider,
  useTheme,
} from "./contexts/ThemeContext";
import LeadUpload from "./pages/admin/LeadUpload";
import CreateLead from "./pages/agent/CreateLead";

const ThemedApp = () => {
  const { actualTheme } = useTheme();

  const theme = createTheme({
    palette: {
      mode: actualTheme,
    },
  });

  return (
    <MUIThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />

          {/* Admin routes */}
          <Route
            path="/admin"
            element={
              <ProtectedRoute>
                <AdminLayout />
              </ProtectedRoute>
            }
          >
            <Route index element={<Navigate to="dashboard" replace />} />
            <Route path="dashboard" element={<AdminDashboard />} />
            <Route path="enroll-agent" element={<AgentEnrollment />} />
            <Route path="agents" element={<AgentList />} />
            <Route path="leads" element={<LeadManagement />} />
            <Route path="reports" element={<Reports />} />
            <Route path="profile" element={<ProfileManagement />} />
            <Route path="settings" element={<Settings />} />
            <Route path="upload-leads" element={<LeadUpload />} />
          </Route>

          {/* Agent routes */}
          <Route
            path="/agent"
            element={
              <ProtectedRoute>
                <AgentLayout />
              </ProtectedRoute>
            }
          >
            <Route index element={<Navigate to="dashboard" replace />} />
            <Route path="dashboard" element={<AgentDashboard />} />
            <Route path="assigned-leads" element={<AssignedLeads />} />
            <Route path="edit-lead/:customerId" element={<EditLead />} />
            <Route path="create-lead" element={<CreateLead />} />
            <Route path="profile" element={<ProfileManagement />} />
            <Route path="settings" element={<Settings />} />
          </Route>

          <Route path="/" element={<Navigate to="/login" replace />} />
        </Routes>
      </BrowserRouter>
    </MUIThemeProvider>
  );
};

const App = () => {
  return (
    <CustomThemeProvider>
      <ThemedApp />
    </CustomThemeProvider>
  );
};

export default App;
